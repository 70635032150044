import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { supportingContentData } from './utils/supportingContent';
const resources = {
  en: {
    translation: {
      ...supportingContentData.en,
      virtualOnly: 'Virtual Only',
      notEligible: 'You are not eligible',
      Skip: 'Skip',
      check_policy_term:
        'I agree to the Abbot Terms and Conditions and the Privacy Policy',
      third_parties_term:
        'I authorize Abbott to disclose my personal data* to selected third parties to deliver value added services to me as a My Abbott Cares(MAC) club member',
      paragraph: 'Browse by Modules',
      LetsGo: "Let's Go",
      other_parameter: 'Other Parameters',
      reflection: 'Reflection',
      font_size: 'Font Size',
      Lifestyle_Check_Questionnaire: 'Lifestyle Check Questionnaire',
      Action: 'Action',
      HOME: 'HOME',
      ME: 'ME',
      SKIP: 'SKIP',
      CONSULTATION: 'CONSULTATION',
      choose_family_information: 'Choose Family Information',
      RECORDS: 'RECORDS',
      REFER: 'REFER FRIEND',
      HELP: 'HELP',
      LANGUAGE: 'Language',
      User_Profile: 'User Profile',
      My_Address: 'My Address',
      current_anthrop_details: 'Current Anthrop Details',
      Progress: 'Progress',
      Medical_Profile: 'Medical Profile',
      FONT: 'FONT SIZE:',
      LOG: 'LOG OUT',
      Records: 'Records',
      select: 'Select which records you would like to access',
      Check_personal: 'Check your personal information here',
      View_Records: 'View Records',
      antrhop: 'Current Anthrop Details',
      check_antrhop: 'Check your anthrop details here',
      Check_Progress: 'Check your progress here',
      Check_medical: 'Check your medical information here',
      Save: 'Save',
      Submit: 'Submit',
      Phone_Number: 'Phone Number',
      Email_Address: 'Email Address',
      Gender: 'Gender',
      Male: 'Male',
      Female: 'Female',
      Date_of_Birth: 'Date of Birth',
      First_Name: 'First Name',
      Last_Name: 'Last Name',
      Height: 'Height',
      Weight: 'Weight',
      updated_by: 'Updated by',
      Waist_Circumference: 'Waist Circumference',
      Click_Milestone:
        'Click on the Milestone & Module to view detailed report.',
      Important_Milestone: 'Important Milestone',
      Completion_Date: 'Completion Date: ',
      Click_on_the: 'Click on the',
      button_to_see_Doctor: 'button to see Advocate’s Note',
      Diagnosis_Year: 'Diagnosis Year',
      updated_by: 'Updated by',
      Edit: 'Edit',
      Appointment_History: 'Appointment History',
      No_appointment_booked_yet: 'No appointment booked yet',
      Book_Appointment: 'Book Virtual Appointment',
      About: 'About',
      Qualifications: 'Qualifications:',
      Appointment: 'Appointment',
      Select_Date: 'Select Date',
      Select_Available_Time_Slot: 'Select Available Time Slot',
      Unavailable_Time_Slot: 'There is no available time slot to book.',
      next: 'next',
      Next: 'Next',
      Selected_Slot: 'Selected Slot',
      Change_Date: 'Change Date',
      Confirm: 'Confirm',
      Call_Now: 'Call Now',
      Remember_Me: 'Remember Me',
      Remember: 'Remember',
      Main_Loading_Page_Welcome: 'To log in, please provide your phone number',
      complete_video_popup:
        'Kindly proceed to watch our video on how you can "Manage Your Diabetes Better in 12 Weeks."',
      ThankYou: 'Thank you',
      // Main Landing Page
      Who_Are_You: 'Who are you?',
      Please_Kindly_Select_The_Rel: 'Please do kindly select the relevant',
      Website_To_Proceed: 'website to proceed',
      Im_A: "I'm a",
      Pt_Intro_Page_Slide_1: 'A lifestyle change journey to a healthier you.',
      // Patient intro Page
      Pt_Intro_Page_Slide_3:
        'Designed to help make you feel more energetic in your day-to-day life.',
      Pt_Intro_Page_Slide_2:
        'Improve your eating habits, increase your physical activity & take charge of your blood sugar.',
      Pt_Intro_Page_Slide_4:
        'Make small impactful changes to your lifestyle to live well with diabetes.',

      // Patient Register Page
      Lets_Get_Started: "Let's get started by keying in",
      Your_Phone_Number: 'your phone number!',
      Enter_Phone_Number: 'Enter phone number',
      Only_Available_In_Malaysia: 'Only available for Malaysia mobile number',
      Send_OTP: 'Send OTP',

      // Patient OTP Page
      Enter_6_Digit_Code: 'Enter the 6-digit OTP code',
      Sent_To_Your_Phone_Num: 'sent to your phone number',
      OTP_Number: 'OTP Number',
      Resend_OTP_Code: 'Resend OTP code',
      consentNotice: 'consent notice',
      // Patient OTP Modal Disclaimer n Consent Modal
      By_Using_The_Web_Based_App: 'By using the web based app.',
      You_Agree_For_Practices: 'You agree for the practices described in',
      This_dalam: 'this',
      Privacy_Notice: `Abbott's privacy`,
      And_Agree_To: 'and agree to',
      Abott_Privacy_Policy: "Abbott's Privacy Policy",
      I_Have_Read: 'I have read and understood the terms of this',
      Dan_And: 'and',
      And_Consent_To_Process_Data:
        'and consent to the processing of my Personal Data as described.',
      I_Would_Also_Like_To_Receive_Comm:
        'I would also like to receive communication from Abbott by phone / email / SMS / WhatsApp regarding Abbott products and services, or for research purposes and DLP activities.',
      Proceed: 'Proceed',
      started: "Let's get started",
      Start: 'START',
      'DLP Advocate': 'DLP Advocate',
      In_this_module: 'In this module, you will need to complete:',
      // Patient First Time Reg
      First_Time_Registration: 'First Time Registration',
      Registration: 'Registration',
      Fill_In_Your_Reg_Details: 'Fill in your registration details below',
      // Key Doctor Page
      Your_Doctor_Info: "Your Advocate's Information",
      Doctor_Name: "Advocate's Name",
      If_Above_Info_Correct: 'If the above information is correct,',
      Enter_Correct_Doctor_Code_To_Confirm:
        "enter your DLP advocate's code to confirm",
      // Personal Details
      homeDoctorWord: 'DLP Advocate',
      Your_Personal_Details: 'Your Personal Details',
      // Medical Info
      Your_Medical_Info: 'Your Medical Information',
      Part_1: 'PART 1',
      Part_2: 'PART 2',
      Your_Measurements: 'Your Measurements',
      Your_Health_Related_Info: 'Your Health Related Information',
      confirmAppointment: 'Confirm Appointment',
      Usual_Weight: 'Usual Weight',
      Current_Weight: 'Current Weight',
      Date_Taken: 'Date Taken',
      Have_You_Noticed_Any_Weight_Changes:
        'Have you noticed any weight changes in the last 5 years?',
      Increased: 'Increased',
      Decreased: 'Decreased',
      No_Sig_CHange: 'No Significant Change',
      My_Weight_Fluctuate: 'My weight has been fluctuating',
      Year_Of_Type_2: 'Year of Type 2 Diabetes Diagnosis',
      keepLogin: 'Keep me log in',
      login: 'Login',
      'Medication Regime': 'Medication Regime',
      // Diabetes Checkbox
      Diabetes_Related_Complications: 'Diabetes related complications',
      Heart_Disease: 'Heart disease/cardiovascular disease',
      Kidney_Disease: 'Kidney disease',
      High_Blood_Pressure: 'High blood pressure',
      Stroke: 'Stroke',
      Foot_Comp: 'Foot complications',
      Eye_Comp: 'Eye complications',
      Others: 'Others',
      // Fields Med Info
      BP: 'Blood Pressure',
      Heart_Rate: 'Heart Rate',
      // Optional
      Optional: 'Optional',
      mandatory: 'Mandatory',
      FBG: 'Fasting Blood Glucose',
      Post_Prandial: 'Post-Prandial blood glucose',
      RBG: 'Random Blood Glucose',
      Total_Cholesterol: 'Total Cholesterol',
      Triglycerides: 'Triglycerides',
      Chol_HDL_Ratio: 'Cholesterol/HDL Ratio',
      // Upload File Med Info
      OR: 'OR',
      Upload_Lab_Rep_Here: 'Upload my Laboratory Report here(optional)',
      File_Uploaded_Success: 'File Uploaded Successfully',
      Upload_File: 'Upload File',
      // Add Family Med Info
      Close_Fam_Info: 'Close Family Information(Optional)',
      You_Can_Add_3_Fam: 'You can only add up to 3 Family Members',
      Full_Name: 'Full Name',
      Mobile_Num: 'Mobile Number',
      Relationship: 'Relationship with Patient',
      Add_Fam_Members: 'Add family members',
      By_Check_U_Agree_Fam_Can_See:
        'You agree that your family members will be able to see your medical report.',
      // Modal Success Register
      Thank_You_Register: 'Thank you for registering!',
      Welcome_To_DLP: 'Welcome to the Diabetes Lifestyle Programme',
      Lets_Begin_Journey: "Let's begin the journey",

      // FTJ Page
      Add_To_Home_Screen_Tut: 'Add To Home Screen Tutorial',
      Add_Your_Programme_to_Home: 'Add your programme to homescreen',
      Follow_These_Steps_Memory_Free:
        'Follow these steps to get hassle-free access to the DLP App without sacrificing your memory space.',
      You_Can_Also_Receive_Real_Time:
        'You can also receive real time messages from us so that you are always first to be updated.',
      Select_Your_Phone_Type: 'Select Your Phone Type',
      Tutorial: 'Tutorial',
      Watch_Out_For_Message:
        'Watch out for your message. Your key to the first module will be arriving soon!',
      complete_watch_video:
        "You've completed the task for this week, 1 more to go!",
      rewatch_video: 'Thank you for watching!',
      welcome: 'Welcome...',
      welcome2: 'Welcome!',
      thank_you:
        'Thank you for registering. Welcome to the Diabetes Lifestyle Programme',
      incomplete: 'You have not finished module ',
      incomplete_module_1: 'You havent finished module 1 action!',
      continue_module_1_button: 'Continue Module 1 action',
      refer_friend: 'Refer Friend',
      invite_close_friend: 'Invite your close friend',
      click_to_invite_friend: 'Click the share button below to refer friend',
      Wait_for_them: 'Wait for them to click the link',
      complete_share: 'Complete',
      now_your_close_friend: 'Now your close friend can also refer you.',
      copy: 'Copy',
      share: 'Share',
      Success_Registered: 'Your store account has been successfully registered',
      Success: 'Success',
      Unsuccessful: 'Unsuccessful',
      Existed_Phone_Verify: 'This phone number is already registered',
      Phone_Verify: 'Please use a different phone number',
      Close: 'Close',
      or: 'or',
      COMPLETED: 'COMPLETED',
      My_Progress: 'My Progress',
      see_your_completion: 'View your module completion status here',
      go_to_home: 'Go to Home',
      complete_all:
        "You've completed all the tasks for the week. Well done in trying to be healthy!",
      Continue: 'Continue',
      ContinueM: 'Continue',
      Back: 'Back',
      ModuleBack: 'Back',
      disclaimer: '"Disclaimer & Consent"',
      Ok: 'Ok',
      family: 'Family',
      MaybeLater: 'Maybe Later',
      Okay: 'Okay',
      Diabetes: 'Diabetes',
      Patient: 'Patient',
      Doctor: 'Advocate',
      Dietitian: 'Dietitian',
      swipeToContinue: 'Swipe to Continue',
      techEnquiry: 'Monday - Friday',
      operationHour: 'Business Hours: 9:00am - 5:00pm',
      dlpProgramme: 'Diabetes Lifestyle Programme',
      Wonderful: 'Wonderful',
      Video: 'Video',
      NoVideo: 'No Video',
      NoReflection: 'No Reflection',
      foodFacts: 'Food Facts',
      Add: 'Add',
      Cancel: 'Cancel',
      clickInfo:
        'Choose on the family members name if you want to edit their information',
      clickInfo2: 'if you want to edit their information',
      Parents: 'Parents',
      Child: 'Child',
      Husband: 'Husband',
      Wife: 'Wife',
      Cousin: 'Cousin',
      Grandparents: 'Grandparents',
      answered: 'You have answered this module.',
      completeT1: 'Congratulation on Completing',
      completeT2: 'the Module 4!',
      completeT3: 'Time to book your first',
      completeT4: 'appointment with our dietitian!',
      Yes: 'Yes',
      No: 'No',
      learn1: 'A lifestyle change journey to a healthier you.',
      learn2: 'healthy lifestyle for diabetes',
      Morning: 'Morning',
      Evening: 'Evening',
      days_to_go: 'Days to go',
      targetValue: 'Target value',
      bloodGlucose: 'Fasting blood glucose',
      postGlucose: 'Post-Prandial blood glucose',
      measuredAfterMeal: 'measured at least 90 mins after meal ',
      veryHighRisk: 'very high risk',
      your_appointment: 'Your Appointment',
      highRisk: 'high risk',
      moderateRisk: 'moderate risk',
      Note_reminder:
        'Note: Reminder will be send out on 3 days, 1 days and 30 mins before the appointment',
      Click_advocate_note: "Click the button to see Advocate's Note",
      forMost: 'For most',
      fill_in_the_form: 'Please complete the form to proceed',
      tight: 'tight',
      Your_feedback_is_appreciated: 'Your feedback is appreciated',
      inputError: 'Please select at least one diabetes related complications',
      fileUploadError: 'Your advocate already uploaded your lab report.',
      helpdesk: 'Tech Support',
      Please_note_quality: 'Please rate the quality of your call',
      unlockRewardsAwait: 'Unlock rewards upon completion of this module!',
      LEARN: 'LEARN',
      Lipid_Profile: 'Lipid Profile',
      Usual: '(Usual)',
      Current: '(Current)',
      Blood_Pressure: 'Blood Pressure',
      Health_Related_Details: 'Health Related Details',
      Not_Now: 'Not Now',
      Measurement_Details: 'Measurement Details',
      What_could_we_do_to_improve: 'What could we do to improve?',
      module9aWait: 'Please complete Module 1 to 8 to unlock your rewards.',
      Trial_Module: 'Trial Module',
      Registered_Patient: 'Registered Patient',
      You_have_completed_all_trial_modules:
        'You have completed all trial modules, speak to your healthcare provider about DLP!',
      'You are on track!': 'You’re on track!',
      Meals: 'Meals',
      Logout: 'Logout',
      Loading: 'Loading',
      Submit_Successful: 'Submit Successful',
      BG_Check_Report: '7-point BG Check Report',
      module5_action_extra_text:
        'Now that you’ve seen all, choose ONE activity that you wish to do from the above & consult your doctor in the next visit to check if this is a safe & suitable activity for you before you get moving!',
      'Test your knowledge by responding': 'Test your knowledge by responding',
      'to the following statements.': 'to the following statements.',
      True: 'True',
      False: 'False',
      'MY PLEDGE TO MAKE LASTING CHANGES': 'MY PLEDGE TO MAKE LASTING CHANGES',
      'You do not have a doctor assigned to you. Please contact your clinic to get your doctor code.':
        'You do not have a doctor assigned to you. Please contact your clinic to get your doctor code.',
      'The remaining modules':
        'The remaining modules will require the guidance of a healthcare provider who is a DLP Advocate. If you are interested to be part of this programme in the future, kindly proceed to fill in you details. Thank you.',
      'You have finished the trial modules of DLP':
        'You have finished the trial modules of DLP',
      'All information recorded':
        'All information recorded are kept private & confidential',
      'My Healthcare Provider is a': 'My Healthcare Provider is a',
      'Sign up now': 'Sign up now',
      'Uploaded Successfully': 'Uploaded Successfully',
      'Preview Next Module': 'Preview Next Module',
      Module: 'Module',
      Tips: 'Tips',
      'Barriers to Exercise': 'Barriers to Exercise',
      'Recommended Safe Exercise': 'Recommended Safe Exercise',
      'Safety Tips': 'Safety Tips',
      'Review Lifestyle Check & Progress': 'Review Lifestyle Check & Progress',
      'Long-term goals': 'Long-term goals',
      'No notes available': 'No notes available',
      Delete: 'Delete',
      'Are you sure you want to delete this Family Member?':
        'Are you sure you want to delete this Family Member?',
      select: 'Select',
      'Click Services Sdn Bhd':
        'Click Services Sdn Bhd, an appointed vendor by Abbott Laboratories (M) Sdn Bhd (“Abbott”) is collecting personal information relating to you as part of the Diabetes Lifestyle Programme ( “DLP”) that will run from Dec 2021 to Dec 2023.',
      'Before you submit any information':
        'Before you submit any information, you should be aware of how it will be used. We will use your information for data analysis and  market research purposes. We may also share this information with our affiliates and subsidiaries as well as authorized independent third parties who may assist us in collecting and processing information. The information you submit may be transferred to other countries that may not have laws protecting personal information information and providing your consent below, you agree to have read and understood',
      "Abbott's Privacy Policy": "Abbott's Privacy Policy",
      'and consent':
        'and consent to the collection, use and disclosure of your personal information. If you have any questions, please contact wecare@abbott.com or call Abbott Nutrition Careline at 1800-88-6233 (Monday-Friday;9am-6pm)',
      'Privacy Policy DLP': 'Privacy Policy DLP',
      'Welcome! This module is exclusively available to':
        'Welcome! This module is exclusively available to',
      'patients appointed': 'patients appointed',
      'by our partner doctors': 'by our partner doctors',
      'To access these modules':
        'To access these modules, please locate and consult our partner doctors for an appointment. Find doctors nearby on the map below for further information and appointments.',
      'Advocate’s Comment': 'Advocate’s Comment',
      DIETITIAN: 'DIETITIAN',
      bookADietitian: 'BOOK A DIETITIAN',
      "You're on track!": `You're on track!`,
      'Select Meal': 'Select Meal',
      Hello: 'Hello',
      Your_journey: `Your journey towards a healthier lifestyle does not end here. Take care of yourself, celebrate what you've accomplished, set new goals for the future, stay focused on what drives you & keep meeting with your doctor.`,
      'We wish you all the best in your': 'We wish you all the best in your',
      'diabetes healthy lifestyle journey!':
        'diabetes healthy lifestyle journey!',
      bookNow: 'Book Now',
      freeOfCharge: 'The entire programme is free of charge',
    },
  },

  my: {
    translation: {
      ...supportingContentData.bm,
      virtualOnly: 'Maya Sahaja',
      "You're on track!": `Anda di landasan yang betul!`,
      DIETITIAN: 'PAKAR DIET',
      bookADietitian: 'TEMPAH PAKAR DIET',
      updated_by: 'Dikemaskini pada',
      'patients appointed': 'pesakit yang dilantik',
      'Advocate’s Comment': 'Komen Penasihat',
      'To access these modules':
        'Untuk mengakses modul ini, sila cari dan rujuk doktor rakan kongsi kami untuk temujanji. Cari doktor berdekatan pada peta di bawah untuk maklumat lanjut dan temu janji.',
      'by our partner doctors': 'oleh doktor rakan kongsi kami',
      'Welcome! This module is exclusively available to':
        'Selamat datang! Modul ini tersedia secara eksklusif untuk',
      'Click Services Sdn Bhd':
        'Click Services Sdn Bhd, yang telah dilantik oleh Abbott Laboratories (M) Sdn Bhd ("Abbott"), mengumpulkan maklumat peribadi mengenai anda sebagai sebahagian daripada Program Lifesytle Diabetes ("DLP") yang akan dijalankan dari Disember 2021 hingga Disember 2023.',
      'Before you submit any information':
        '"Sebelum anda menghantar sebarang maklumat, anda perlu sedar bagaimana maklumat tersebut akan digunakan. Kami akan menggunakan maklumat anda untuk tujuan analisis data dan penyelidikan pasaran. Kami juga boleh berkongsi maklumat ini dengan syarikat-syarikat afiliasi dan subsidiari serta pihak ketiga bebas yang diluluskan yang boleh membantu kami mengumpulkan dan memproses maklumat. Maklumat yang anda hantar mungkin akan dihantar ke negara lain yang tidak mempunyai undang-undang melindungi maklumat peribadi. Dengan memberikan persetujuan di bawah, anda bersetuju telah membaca dan memahami."',
      "Abbott's Privacy Policy": 'Dasar Privasi Abbott',
      'and consent':
        'dan persetujuan untuk pengumpulan, penggunaan dan pendedahan maklumat peribadi anda. Jika anda mempunyai sebarang pertanyaan, sila hubungi wecare@abbott.com atau hubungi Abbott Nutrition Careline di 1800-88-6233 (Isnin-Jumaat; 9 pagi-6 petang)',
      'Privacy Policy DLP': 'Dasar Privasi DLP',
      'Barriers to Exercise': 'Halangan untuk Bersenam',
      'Are you sure you want to delete this Family Member?':
        'Adakah anda pasti ingin memadam Ahli Keluarga ini?',
      select: 'Pilih',
      Delete: 'Padam',
      'No notes available': 'Tiada nota yang tersedia',
      'Long-term goals': 'Matlamat jangka panjang',
      'Review Lifestyle Check & Progress': 'Semak Gaya Hidup & Progres',
      'Safety Tips': 'Tip Keselamatan',
      'Recommended Safe Exercise': 'Senaman Selamat yang Disyorkan',
      'DLP Advocate': 'Penilai DLP',
      'Medication Regime': 'Regimen Ubat',
      Module: 'Modul',
      Tips: 'Tip',
      'Preview Next Module': 'Pratonton Modul Seterusnya',
      'Uploaded Successfully': 'Berjaya dimuat naik',
      'Sign up now': 'Daftar sekarang',
      'My Healthcare Provider is a': 'Pembekal Penjagaan Kesihatan Saya adalah',
      Not_Now: 'Bukan Sekarang',
      'You have finished the trial modules of DLP':
        'Anda telah menyelesaikan modul percubaan DLP',
      Lipid_Profile: 'Profil Lipid',
      BG_Check_Report: 'Laporan Semakan BG 7 mata',
      Usual: '(Normal)',
      'All information recorded': 'Semua maklumat yang direkodkan',
      Logout: 'Log Keluar',
      'The remaining modules':
        'Modul yang tinggal memerlukan bimbingan penyedia penjagaan kesihatan yang merupakan Pembela DLP. Jika anda berminat untuk menjadi sebahagian daripada program ini pada masa hadapan, sila teruskan untuk mengisi butiran anda. Terima kasih.',
      Lifestyle_Check_Questionnaire: 'Soal Selidik Semakan Gaya Hidup',
      Current: '(Semasa)',
      Measurement_Details: 'Butiran Ukuran',
      Your_feedback_is_appreciated: 'Maklum balas anda dihargai',
      What_could_we_do_to_improve:
        'Apakah yang boleh kita lakukan untuk memperbaiki?',
      Please_note_quality: 'Sila beri penilaian untuk panggilan anda',
      ME: 'SAYA',
      LEARN: 'BELAJAR',
      notEligible: 'Anda tidak layak',
      Note_reminder:
        'Nota: Peringatan akan dihantar pada 3 hari, 1 hari dan 30 minit sebelum temujanji',
      User_Profile: 'Profil Pengguna',
      My_Address: 'Alamat Saya',
      current_anthrop_details: 'Butiran Antrop Semasa',
      Progress: 'Progres',
      Medical_Profile: 'Profil Perubatan',
      your_appointment: 'Temujanji Anda',
      font_size: 'Saiz Font',
      unlockRewardsAwait: 'Tuntut ganjaran setelah menyelesaikan modul ini!',
      choose_family_information: 'Pilih Maklumat Keluarga',
      module9aWait: 'Tuntut ganjaran setelah menyelesaikan modul 1 hingga 8.',
      helpdesk: 'Bantuan Teknikal',
      mandatory: 'Wajib',
      fileUploadError: 'Pakar DLP anda telah memuat naik laporan makmal anda.',
      check_policy_term:
        'Saya bersetuju dengan Terma dan Syarat Abbot dan Polisi Privasi',
      third_parties_term:
        'Saya memberi kebenaran kepada Abbott untuk mendedahkan data peribadi saya kepada pihak ketiga yang dipilih untuk menyampaikan perkhidmatan tambahan kepada saya sebagai ahli kelab My Abbott Cares (MAC)',
      inputError:
        'Sila pilih sekurang-kurangnya satu komplikasi berkaitan diabetes',
      forMost: 'Untuk kebanyakan',
      tight: 'Ketat',
      veryHighRisk: 'sangat berisiko tinggi',
      highRisk: 'berisiko tinggi',
      moderateRisk: 'risiko sederhana',
      Remember_Me: 'Ingat Saya',
      Remember: 'Ingat',
      Registration: 'Pendaftaran',
      fill_in_the_form: 'Sila lengkapkan borang untuk meneruskan',
      Start: 'MULA',
      measuredAfterMeal:
        'bacaan diambil sekurang-kurangnya 90 min selepas makan',
      postGlucose: 'Glukosa darah selepas makan',
      bloodGlucose: 'Glukosa darah puasa',
      targetValue: 'Nilai sasaran',
      learn1: 'Perjalanan perubahan gaya hidup ke arah anda yang lebih sihat.',
      learn2: 'hidup sihat untuk diabetes',
      Yes: 'Ya',
      No: 'Tidak',
      SKIP: 'Langkau',
      completeT1: 'Tahniah kerana Melengkapkan',
      completeT2: 'Modul 4!',
      completeT3: 'Masa untuk menempah janji temu',
      completeT4: 'pertama anda dengan pakar diet kami!',
      answered: 'Anda telah menjawab modul ini.',
      Parents: 'Ibu Bapa',
      Child: 'Anak',
      Husband: 'Suami',
      other_parameter: 'Parameter Lain',
      Wife: 'Isteri',
      Cousin: 'Sepupu',
      Grandparents: 'Atuk Nenek',
      clickInfo: 'Klik pada nama ahli keluarga',
      clickInfo2: 'jika anda ingin mengedit maklumat mereka',
      Add: 'Tambah',
      Cancel: 'Batal',
      foodFacts: 'Fakta Makanan',
      NoReflection: 'Tiada Refleksi',
      Video: 'Video',
      NoVideo: 'Tiada Video',
      Click_advocate_note:
        'Klik pada butang untuk melihat Nota Perunding Kesihatan',
      Wonderful: 'Hebatnya',
      dlpProgramme: 'Program Gaya Hidup Diabetes',
      techEnquiry: 'Isnin - Jumaat ',
      operationHour: 'Waktu Perniagaan: 9AM - 5PM',
      swipeToContinue: 'Leret untuk Meneruskan',
      Diabetes: 'Diabetes',
      Patient: 'Pesakit',
      Doctor: 'Pakar DLP',
      Dietitian: 'Pakar Diet',
      Appointment: 'Temu Janji',
      Okay: 'Baik',
      family: 'Keluarga',
      MaybeLater: 'Mungkin Nanti',
      Ok: 'Seterusnya',
      Skip: 'Langkau',
      Continue: 'Hantar',
      ContinueM: 'Seterusnya',
      Back: 'Kembali',
      ModuleBack: 'Kembali',
      paragraph: 'Semak imbas mengikut modul',
      LetsGo: 'Mari Mulakan',
      reflection: 'Refleksi',
      Action: 'Tindakan',
      HOME: 'LAMAN UTAMA',
      Submit: 'Hantar',
      CONSULTATION: 'JANJI TEMU',
      RECORDS: 'REKOD',
      REFER: 'RUJUK RAKAN',
      HELP: 'BANTUAN',
      LANGUAGE: `Bahasa`,
      FONT: 'SAIZ FON:',
      Submit_Successful: 'Berjaya dihantar',
      LOG: 'LOG KELUAR',
      Records: 'Rekod',
      select: 'Pilih rekod yang anda ingin akses',
      Check_personal: 'Semak maklumat peribadi anda di sini',
      View_Records: 'Lihat Rekod',
      antrhop: 'Butiran antrhop semasa',
      check_antrhop: 'Semak butiran antrhop anda di sini',
      Check_Progress: 'Semak progres anda di sini',
      Check_medical: 'Semak maklumat perubatan anda di sini',
      Save: 'Simpan',
      Phone_Number: 'Nombor Telefon',
      Email_Address: 'Alamat Email',
      Gender: 'Jantina',
      Male: 'Lelaki',
      days_to_go: 'hari lagi',
      Female: 'Perempuan',
      Date_of_Birth: 'Tarikh Lahir',
      First_Name: 'Nama Pertama',
      Last_Name: 'Nama Akhir',
      Height: 'Tinggi',
      Weight: 'Berat',
      Waist_Circumference: 'Lilitan Pinggang',
      Click_Milestone:
        'Klik pada Pencapaian & Modul untuk melihat laporan terperinci.',
      Important_Milestone: 'Pencapaian Penting',
      Completion_Date: 'Tarikh Siap: ',
      Click_on_the: 'Klik pada',
      button_to_see_Doctor: 'butang untuk melihat komen Pakar DLP.',
      Diagnosis_Year: 'Tahun Diagnosis',
      updated_by: 'Dikemaskini pada',
      Edit: 'Kemaskini',
      Appointment_History: 'Senarai Temu Janji',
      No_appointment_booked_yet: 'Tiada temu janji lagi',
      Book_Appointment: 'Tempah Temu Janji',
      About: 'Tentang',
      Qualifications: 'Kelayakan:',

      Select_Date: 'Pilih Tarikh',
      Select_Available_Time_Slot: 'Pilih Slot Masa Tersedia',
      Unavailable_Time_Slot: 'Tiada slot masa yang tersedia untuk ditempah.',
      next: 'Seterusnya',
      Next: 'Seterusnya',
      Selected_Slot: 'Slot Yang Dipilih',
      Change_Date: 'Tukar Tarikh',
      Confirm: 'Sahkan',
      Call_Now: 'Hubungi Sekarang',
      complete_video_popup:
        'Sila teruskan menonton video kami tentang bagaimana anda boleh "Mengurus Diabetes Anda Dengan Lebih Baik Dalam 12 Minggu".',
      ThankYou: 'Terima kasih',
      complete_watch_video:
        'Anda telah menonton video untuk minggu ini, tinggal 1 lagi!',
      rewatch_video: 'Terima kasih kerana menonton!',
      welcome: 'Selamat datang...',
      welcome2: 'Selamat datang!',
      thank_you:
        'Terima Kasih kerana mendaftar. Selamat datang ke Program Gaya Hidup Diabetes',
      incomplete: 'Anda masih belum menyiapkan modul ',
      incomplete_module_1: 'Anda masih belum menyiapkan tindakan modul 1!',
      continue_module_1_button: 'Teruskan tindakan modul 1',
      refer_friend: 'Rujuk Rakan',
      invite_close_friend: 'Jemput kenalan rapat anda',
      click_to_invite_friend: 'Klik butang kongsi di bawah untuk merujuk rakan',
      Wait_for_them: 'Tunggu mereka klik pautan',
      complete_share: 'Siap',
      Main_Loading_Page_Welcome:
        'Untuk log masuk, sila berikan nombor telefon anda',
      now_your_close_friend:
        'Sekarang rakan karib anda juga boleh merujuk anda.',
      copy: 'Salin',
      share: 'Kongsi',
      or: 'atau',
      Loading: 'Memuat',
      My_Progress: 'Progres Saya',
      see_your_completion: 'Lihat status kelengkapan modul anda di sini',
      go_to_home: 'Pergi ke halaman utama',
      complete_all:
        'Anda telah menyelesaikan semua tugas untuk modul ini. Tahniah atas usaha anda dalam mencuba untuk sihat!',

      // Main Landing Page
      Who_Are_You: 'Siapakah anda?',
      Please_Kindly_Select_The_Rel: 'Sila pilih',
      Website_To_Proceed: 'website yang berkaitan dengan anda',
      Im_A: 'Saya adalah',
      Pt_Intro_Page_Slide_1:
        'Perjalanan perubahan gaya hidup untuk membantu anda menjadi lebih sihat.',
      // Patient Intro
      Pt_Intro_Page_Slide_3:
        'Direka untuk membantu anda berasa lebih bertenaga dalam melakukan aktiviti seharian.',
      Pt_Intro_Page_Slide_2:
        'Membaiki tabiat pemakanan, meningkatkan aktiviti fizikal & mengawal gula darah anda.',
      homeDoctorWord: 'Pakar DLP',
      Pt_Intro_Page_Slide_4:
        'Lakukan perubahan kecil yang memberi impak pada gaya hidup anda agar anda boleh hidup sihat dengan diabetes.',
      disclaimer: '"Penafian & Persetujuan"',
      // Patient Register Page
      Lets_Get_Started: 'Mari mulakan dengan memasukkan',
      Your_Phone_Number: 'nombor telefon anda!',
      Enter_Phone_Number: 'Masukkan nombor telefon',
      Only_Available_In_Malaysia: 'Hanya untuk nombor mudah alih Malaysia',
      Send_OTP: 'Hantar OTP',

      // Patient OTP Page
      Enter_6_Digit_Code: 'Masukkan kod OTP 6 digit',
      Sent_To_Your_Phone_Num: 'yang dihantar kepada nombor telefon bimbit anda',
      OTP_Number: 'Nombor OTP',
      Resend_OTP_Code: 'Hantar Semula Kod OTP',
      consentNotice: 'notis persetujuan',
      // Patient OTP Modal Disclaimer n Consent Modal
      By_Using_The_Web_Based_App: 'Dengan menggunakan aplikasi berasaskan web,',
      You_Agree_For_Practices: 'anda bersutuju dengan amalan yang diterangkan',
      This_dalam: 'dalam',
      Privacy_Notice: "Abbott's privasi",
      And_Agree_To: 'ini dan bersetuju dengan',
      Abott_Privacy_Policy: 'Dasar Privasi Abbott',
      I_Have_Read: 'Saya telah membaca dan memahami syarat-syarat ini',
      Dan_And: 'dan',
      And_Consent_To_Process_Data:
        'dan persetujuan untuk pemprosesan Data Peribadi saya seperti yang diterangkan.',
      I_Would_Also_Like_To_Receive_Comm:
        'Saya juga ingin menerima komunikasi daripada Abott melalui telefon / e-mel / SMS / WhatsApp mengenai produk dan perkhidmatan Abott, atau untuk tujuan penyelidikan dan aktiviti DLP.',
      Proceed: 'Seterusnya',
      Morning: 'Pagi',
      Evening: 'Petang',
      started: 'Mari kita mulakan',
      In_this_module: 'Dalam modul ini, anda perlu melengkapkan: ',
      confirmAppointment: 'Sahkan Temu Janji',
      // Patient First Time Reg
      First_Time_Registration: 'Pendaftaran Kali Pertama',
      Fill_In_Your_Reg_Details: 'Isikan butiran pendaftaran anda di bawah',
      // Key Doctor Page
      Your_Doctor_Info: 'Maklumat Pakar DLP Anda',
      Doctor_Name: 'Nama Pakar DLP',
      If_Above_Info_Correct: 'Jika maklumat di atas adalah betul,',
      Enter_Correct_Doctor_Code_To_Confirm:
        'masukkan kod pakar DLP anda untuk mengesahkan',
      // Personal Details
      Your_Personal_Details: 'Butiran Peribadi Anda',
      // Medical Info
      Your_Medical_Info: 'Maklumat Perubatan Anda',
      Part_1: 'BAHAGIAN 1',
      Part_2: 'BAHAGIAN 2',
      Your_Measurements: 'Ukuran Anda',
      Your_Health_Related_Info: 'Maklumat Berkaitan Kesihatan Anda',
      Usual_Weight: 'Berat Biasa',
      Current_Weight: 'Berat Semasa',
      Date_Taken: 'Tarikh Diambil',
      Have_You_Noticed_Any_Weight_Changes:
        'Adakah anda perasan apa-apa perubahan berat badan dalam tempoh 5 tahun yang lalu?',
      Increased: 'Meningkat',
      Decreased: 'Mengurang',
      No_Sig_CHange: 'Tiada Perubahan Ketara',
      My_Weight_Fluctuate: 'Berat Badan Tidak Stabil',
      Year_Of_Type_2: 'Tahun Diagnosis Diabetes Jenis 2',
      // Diabetes Checkbox
      Diabetes_Related_Complications: 'Komplikasi berkaitan diabetes',
      Heart_Disease: 'Penyakit Kardiovaskular/Jantung',
      Kidney_Disease: 'Penyakit Buah Pinggang(Nefropati)',
      High_Blood_Pressure: 'Tekanan Darah Tinggi',
      Stroke: 'Strok',
      Health_Related_Details: 'Butiran Kesihatan Berkaitan',
      Blood_Pressure: 'Tekanan Darah',
      Foot_Comp: 'Komplikasi Kaki',
      Eye_Comp: 'Komplikasi Mata',
      Others: 'Lain-lain',
      // Fields Med Info
      BP: 'Tekanan Darah',
      Heart_Rate: 'Kadar degupan jantung',
      // Optional
      Optional: 'Tambahan',
      FBG: 'Glukosa Darah Puasa',
      Post_Prandial: 'Glukosa darah post-Prandial',
      RBG: 'Glukosa Darah Rawak',
      Total_Cholesterol: 'Jumlah Kolesterol',
      Triglycerides: 'Trigliserida',
      Chol_HDL_Ratio: 'Nisbah Kolesterol/HDL',
      // Upload File Med Info
      OR: 'ATAU',
      COMPLETED: 'SELESAI',
      Upload_Lab_Rep_Here: 'Muat Naik Laporan Kesihatan Saya(pilihan)',
      File_Uploaded_Success: 'Fail berjaya dimuat naik',
      Upload_File: 'Muat Naik Fail',
      // Add Family Med Info
      Close_Fam_Info: 'Maklumat Keluarga Terdekat(Pilihan)',
      You_Can_Add_3_Fam: 'Anda hanya boleh menambah sehingga 3 Ahli Keluarga',
      Full_Name: 'Nama Penuh',
      Mobile_Num: 'Nombor Telefon',
      Relationship: 'Hubungan dengan pesakit:',
      Add_Fam_Members: 'Tambah ahli keluarga',
      By_Check_U_Agree_Fam_Can_See:
        'Anda bersetuju bahawa ahli keluarga anda akan dapat melihat laporan perubatan anda.',
      // Modal Success Register
      Thank_You_Register: 'Terima Kasih kerana mendaftar!',
      Welcome_To_DLP: 'Selamat datang ke Program Gaya Hidup Diabetes',
      Lets_Begin_Journey: 'Mari mulakan perjalanan',
      keepLogin: 'Teruskan saya log masuk',
      login: 'Log Masuk',
      // FTJ Page
      Add_To_Home_Screen_Tut: 'Tutorial penambahan aplikasi pada skrin utama',
      Add_Your_Programme_to_Home: 'Tambahkan program anda pada skrin utama',
      Follow_These_Steps_Memory_Free:
        'Ikuti langkah ini untuk mengakses aplikasi DLP dengan mudah tanpa menggunakan memori telefon bimbit anda.',
      You_Can_Also_Receive_Real_Time:
        'Anda juga boleh menerima mesej masa nyata daripada kami supaya anda menjadi yang pertama menerima maklumat terkini.',
      Select_Your_Phone_Type: 'Pilih Jenis Telefon Anda',
      Watch_Out_For_Message:
        'Perhatikan peti e-mel anda dan hidupkan notifikasi. Kunci modul pertama anda akan tiba tidak lama lagi!',
      Success_Registered: 'Akaun kedai anda telah berjaya didaftarkan',
      Success: 'Berjaya',
      Unsuccessful: 'Tidak Berjaya',
      Existed_Phone_Verify: 'Nombor telefon ini telah didaftarkan sebelum ini',
      Phone_Verify: 'Sila gunakan nombor telefon yang berbeza',
      Close: 'Tutup',
      Trial_Module: 'Modul Percubaan',
      Registered_Patient: 'Pesakit Berdaftar',
      You_have_completed_all_trial_modules:
        'Anda telah menyelesaikan semua modul percubaan, berbincang dengan pembekal penjagaan kesihatan anda tentang DLP!',
      'You are on track!': 'Anda di landasan yang betul!',
      Meals: 'Makanan',
      module5_action_extra_text:
        'Sekarang apabila anda telah melihat semua, pilih SATU aktiviti yang anda ingin lakukan dari senarai di atas & rujuk doktor anda dalam lawatan seterusnya untuk memeriksa sama ada aktiviti ini selamat & sesuai untuk anda sebelum anda bermula!',
      'Test your knowledge by responding':
        'Uji pengetahuan anda dengan memilih',
      'to the following statements.': 'bagi pernyataan berikut.',
      True: 'Benar',
      False: 'Salah',
      'MY PLEDGE TO MAKE LASTING CHANGES':
        'JANJI UNTUK MEMBUAT PERUBAHAN YANG KEKAL',
      'You do not have a doctor assigned to you. Please contact your clinic to get your doctor code.':
        'Anda tidak mempunyai doktor yang ditugaskan kepada anda. Sila hubungi klinik anda untuk mendapatkan kod doktor anda.',
      'Select Meal': 'Pilih Makanan',
      Hello: 'Hai',
      Select_Date: 'Pilih Tarikh',
      Your_journey: `Perjalanan anda ke arah gaya hidup sihat tidak berakhir di sini. Jaga diri anda, raikan apa yang telah capai, tetapkan matlamat baharu untuk masa depan, kekal fokus pada perkara yang mendorong anda & terus berjumpa dengan doktor anda.`,
      'We wish you all the best in your':
        'Kami mendoakan yang terbaik untuk anda dalam',
      'diabetes healthy lifestyle journey!':
        'perjalanan gaya hidup sihat diabetes!',
      bookNow: 'Tempah Sekarang',
      'You have done your': 'Anda telah menyelesaikan',
      appointmentBook: 'janji temu anda! Tempah janji temu',
      appointmentNow: 'seterusnya sekarang!',
      freeOfCharge: 'Program ini adalah percuma',
    },
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'my',
    fallbackLng: 'my',
    keySeparator: '.',
    returnObjects: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
